<template>
    <main class="my-account">
		
<section class="content-my-account">
	<div class="content-my-account-itens">
		<div class="content-my-account-itens-bg">
			<div class="container">
				<div class="content-my-account-itens-header">
					<!-- <router-link class="btn-return" to="/">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.586" height="10.582" viewBox="0 0 9.586 10.582"><path d="M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z" transform="translate(5.256 12.867)"/></svg>
						<p>voltar</p>
					</router-link> -->
					<h1 class="title">
						A sua conta
						<!-- if user type 1 show "Revendedor" else show "Gestor" -->
						<span v-if="user.type == 1">Revendedor</span>
						<span v-else>Gestor</span>
					</h1>
					<a @click="onLogoff" class="logout">Terminar sessão</a>
				</div>
			</div>
		</div>
		<div class="content-my-account-itens-body">
			<div class="container">
				<div class="content-account">
					<div class="content-account-element">
						<div class="row">
							<div class="col-md-6">
								<a class="content-account-element-card" @click="$root.openModal('modal-account')">
									<svg id="user-pen" xmlns="http://www.w3.org/2000/svg" width="38" height="30.4" viewBox="0 0 38 30.4">
										<path id="Path_3446" data-name="Path 3446" d="M353.167,235.715a1.068,1.068,0,0,0-.292.546l-.848,4.24a.535.535,0,0,0,.629.629l4.241-.848a1.068,1.068,0,0,0,.546-.292l7.44-7.44-4.276-4.276Zm15.325-9.791-1.253-1.253a2.137,2.137,0,0,0-3.023,0l-2.264,2.265,4.276,4.276,2.264-2.264A2.144,2.144,0,0,0,368.492,225.925Z" transform="translate(-331.116 -210.742)" fill="#00a482"/>
										<path id="Path_3447" data-name="Path 3447" d="M13.247,15.2A7.6,7.6,0,1,0,5.7,7.6,7.6,7.6,0,0,0,13.247,15.2Zm3.064,2.85H10.29A10.3,10.3,0,0,0,0,28.346,2.057,2.057,0,0,0,2.058,30.4h17a2.426,2.426,0,0,1-.017-1.012l.848-4.24a2.958,2.958,0,0,1,.812-1.518l2.715-2.715A10.237,10.237,0,0,0,16.31,18.05Z" fill="#00a482" opacity="0.4"/>
									</svg>
									<p>Os seus dados</p>
									<a @click="$root.openModal('modal-account')">Gerir</a>
								</a>
							</div>
							<div class="col-md-6">
								<a class="content-account-element-card" @click="$root.openModal('modal-password')">
									<svg id="key" xmlns="http://www.w3.org/2000/svg" width="29.649" height="29.649" viewBox="0 0 29.649 29.649">
										<path id="Path_3448" data-name="Path 3448" d="M160,10.192a10.172,10.172,0,1,1,7.082,9.711l-6.6-6.6a10.216,10.216,0,0,1-.481-3.11Zm12.508-4.633a2.316,2.316,0,1,0,2.316,2.316A2.316,2.316,0,0,0,172.508,5.559Z" transform="translate(-150.735)" fill="#00a482"/>
										<path id="Path_3449" data-name="Path 3449" d="M9.746,229.7l6.6,6.6h0l-1.98,1.876a1.087,1.087,0,0,1-.932.457H11.118v2.316a1.386,1.386,0,0,1-1.39,1.39H7.412v2.316a1.386,1.386,0,0,1-1.39,1.39H1.39A1.388,1.388,0,0,1,0,244.658v-4.633a1.39,1.39,0,0,1,.407-.984L9.746,229.7Z" transform="translate(0 -216.398)" fill="#00a482" opacity="0.4"/>
									</svg>
									<p>Password</p>
									<a @click="$root.openModal('modal-password')">Alterar</a>
								</a>
							</div>
						</div>
						<div class="row" v-if="user.type == 1">
							<div class="col-md-6">
								<a class="content-account-element-revendedor" @click="$root.openModal('modal-gestor')">
									<svg id="user-plus" xmlns="http://www.w3.org/2000/svg" width="38" height="30.4" viewBox="0 0 38 30.4">
										<path id="Path_3450" data-name="Path 3450" d="M457.975,132.275h-2.85v-2.85a1.425,1.425,0,0,0-2.85,0v2.85h-2.85a1.425,1.425,0,1,0,0,2.85h2.85v2.85a1.425,1.425,0,1,0,2.85,0v-2.85h2.85a1.425,1.425,0,0,0,0-2.85Z" transform="translate(-421.4 -120.4)" fill="#de9c59"/>
										<path id="Path_3451" data-name="Path 3451" d="M13.3,15.2A7.6,7.6,0,1,0,5.7,7.6,7.6,7.6,0,0,0,13.3,15.2Zm3.01,2.85H10.29A10.291,10.291,0,0,0,0,28.34,2.058,2.058,0,0,0,2.058,30.4H24.543A2.057,2.057,0,0,0,26.6,28.34,10.291,10.291,0,0,0,16.31,18.05Z" fill="#de9c59" opacity="0.4"/>
									</svg>
									<p>Novo gestor</p>
									<a @click="$root.openModal('modal-gestor')">Adicionar</a>
								</a>
							</div>
							<div class="col-md-6">
								<a class="content-account-element-revendedor" @click="goToManager()">
									<svg id="users" xmlns="http://www.w3.org/2000/svg" width="39" height="31.2" viewBox="0 0 39 31.2">
										<path id="Path_3452" data-name="Path 3452" d="M139.694,124.675a6.338,6.338,0,1,0-6.283-6.338A6.292,6.292,0,0,0,139.694,124.675Zm3.047,1.95h-6.082A8.4,8.4,0,0,0,128,134.748a1.678,1.678,0,0,0,1.731,1.627h19.939a1.679,1.679,0,0,0,1.731-1.627A8.4,8.4,0,0,0,142.741,126.625Z" transform="translate(-120.2 -105.175)" fill="#de9c59"/>
										<path id="Path_3453" data-name="Path 3453" d="M7.8,9.75A4.875,4.875,0,1,0,2.925,4.875,4.875,4.875,0,0,0,7.8,9.75Zm23.4,0a4.875,4.875,0,1,0-4.875-4.875A4.875,4.875,0,0,0,31.2,9.75Zm2.431,1.95H29.863a5.081,5.081,0,0,0-2.185.5,8.349,8.349,0,0,1,.1.96,8.215,8.215,0,0,1-2.021,5.363H37.924A1.11,1.11,0,0,0,39,17.385,5.533,5.533,0,0,0,33.631,11.7ZM11.3,12.194A4.4,4.4,0,0,0,9.135,11.7H5.367A5.535,5.535,0,0,0,0,17.385a1.109,1.109,0,0,0,1.074,1.14H13.231a8.215,8.215,0,0,1-2.021-5.363A7.784,7.784,0,0,1,11.3,12.194Z" fill="#de9c59" opacity="0.4"/>
									</svg>
									<p>Os seus gestores</p>
									<router-link tag="a" to="/my-managers">Consultar</router-link>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ModalAccount />
		<ModalChangePassword />
		<ModalNewGestor />
	</div>
</section>

	</main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import ModalAccount from '@/components/ModalAccount';
import ModalChangePassword from '@/components/ModalChangePassword';
import ModalNewGestor from '@/components/ModalNewGestor';

export default {
	components: {
		ModalAccount,
		ModalChangePassword,
		ModalNewGestor
	},
	computed: {
		...mapGetters('User', ['user']),
	},
	methods:{		
        ...mapActions('App', ['loading']),  
		...mapActions('User', ['setUser']) ,
		...mapActions('User', ['logoff']),
		async goToManager(){
			this.$router.push('/my-managers');
		},
		async onPassword(){
			let formData = new FormData(this.$refs.formPassword);
			await this.save(formData)
			this.$refs.formPassword.reset()
		},  
        async onUpdate(el){            
            let formData = new FormData(this.$refs.formUpdate);
			await this.save(formData)
        },
		async save(formData){
			this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/update',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);				
				this.setUser(data.data);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });                
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
		},
		async onLogoff(){
        	this.logoff();
        	document.location.href = "/";
      	}
    }
}
</script>
<style>
.content-my-account-itens-header .title{
	position: relative !important;
}

h1 span{
	background-color: #62A7DC;
	padding: 5px 10px;
	border-radius: 15px;
	position: absolute;
	top: 5px;
	left: 200px;
	font-size: 18px;
	color: #fff;
}

.content-my-account-itens-header .logout{
	all: unset;
	height: 40px;
	width: 140px;
	background-color: transparent;
	padding: 5px 10px;
	border-radius: 5px;
	border: 2px solid #AFAFAF;
	font-size: 18px;
	color: #AFAFAF;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.content-account-element-card{
	all: unset;
	cursor: pointer;
	padding: 15px;
	border-radius: 10px;
	border: 2px solid #01A686;
	height: 153px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.content-account-element-card p{
	font-size: 24px;
	font-family: 'Font Bold';
	color: #01A686;
	margin-bottom: 0;
}

.content-account-element-card a{
	all: unset;
	color: #000000;
	font-size: 18px;
	font-family: 'Font Regular';
	text-decoration: underline!important;
}
.content-account-element-revendedor{
	all: unset;
	cursor: pointer;
	margin-top: 35px;
	padding: 15px;
	border-radius: 10px;
	border: 2px solid #DE9C59;
	height: 153px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.content-account-element-revendedor p{
	font-size: 24px;
	font-family: 'Font Bold';
	color: #DE9C59;
	margin-bottom: 0;
}

.content-account-element-revendedor a{
	all: unset;
	color: #000000;
	font-size: 18px;
	font-family: 'Font Regular';
	text-decoration: underline!important;
}
</style>