<template>
    <!-- MODAL REGISTER -->
    <div class="modal modal-default fade in" tabindex="-1" role="dialog" id="modal-gestor">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <button class="modal-btn-close" @click="$root.closeModal('modal-gestor')">
                    X
                </button>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-header">
                            <h2 class="modal-title">Adicionar gestor</h2>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-body-itens content-my-account-itens-body">
                        <form ref="formNewGestor" @submit.prevent="onNewGestor" class="content-account-element-body">
							<div class="row">
								<div class="col-lg-6">
									<div class="content-input">
										<label>Email gestor</label>
										<input type="email" value="" name="email" placeholder="gestor@email.pt" required/>
                                        <input type="hidden" value="2" name="type">
                                        <input type="hidden" value="4" name="owner_id" />
                                        <input type="hidden" :value="user.id" name="rev_id" />
                                    </div>
								</div>
							</div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-body-itens-form-btns">
                                        <button type="submit">Confirmar</button>
                                        <button class="btn-cancel" @click="$root.closeModal('modal-gestor')">Cancelar</button>
                                    </div>
                                </div>
                            </div>
						</form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
	computed: {
		...mapGetters('User', ['user']),
	},
	methods:{		
        ...mapActions('App', ['loading']),  
		...mapActions('User', ['setUser']) ,
		async onNewGestor(){
			let formData = new FormData(this.$refs.formNewGestor);
			await this.save(formData)
			this.$refs.formNewGestor.reset()
		},
		async save(formData){
			this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/new-gestor',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);				
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });                
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
		},
    }
}
</script>
<style scoped>
.btn-cancel{
    all: unset;
    width: 100px!important;
    height: 50px;
    background-color: #fff!important;
    color: #EE8282!important;
    border: 1px solid #EE8282!important;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>